@import 'src/styles/breakpoints';

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.appBar /* header */ {
  img {
    display: block;
    margin: 0 2rem;
    width: 2em;

    @include mobile {
      margin: 0 1rem;
    }
  }

  .toolbar {
    width: 100%;
    justify-content: space-between;

    @include mobile {
      padding: 0 0.5rem;
    }
  }
}

.footer {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafa;
  border-top: 1px solid #dfdfdf;
  padding: 1.5rem;

  @include mobile {
    flex-direction: column;
  }

  .left {
    display: flex;
    align-items: center;

    @include mobile {
      flex-direction: column;
    }

    a {
      display: inline-block;

      @include desktop {
        margin-left: 1.5rem;
      }

      @include mobile {
        margin-top: 1rem;
      }

      &:first-child {
        margin: 0;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;

    @include mobile {
      flex-direction: column;
      margin-top: 1.5rem;
    }

    img {
      margin-left: 0.5rem;
      vertical-align: middle;

      @include mobile {
        margin-top: 0.5rem;
      }
    }
  }
}
