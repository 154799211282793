/*
 * Global CSS properties.
 *
 * WARNING: Do not add stuff in here unless absolutely necessary.
 * We use CSS Modules to scope styling to within each component, so we don't need to
 * worry about naming clashes. Class names defined within modules are assigned unique hashes.
 *
 * Throughout the project, you'll notice this file naming convention in each component directory:
 * <component-name>.module.scss
 *
 * Learn more about CSS Modules here:
 * https://facebook.github.io/create-react-app/docs/adding-a-css-modules-stylesheet
 */

@import '~fontsource-roboto/latin-300-normal.css';
@import '~fontsource-roboto/latin-400-normal.css';
@import '~fontsource-roboto/latin-500-normal.css';
@import '~fontsource-roboto/latin-700-normal.css';

body {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #333;
  background-color: #fafafa;
}
